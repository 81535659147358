/* This is the styling for the nav container */
.navbar {
  width: 100vw;
  height: 90px;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  background-color: #000;
}
/* This is the styling for the nav content */

.navbar-content {
  padding-top: 25px;
  width: 100%;
  height: 80px;
  align-items: center;
  display: flex;
}

.nav-links {
  padding-top: 10px;
  padding-right: 45px;
}

/* This is the styling for the left nav container */

.left-nav {
  color: #000;
  flex: 30%;
  display: flex;
  align-items: center;
  padding-left: 5%;
}

.left-nav img {
  width: 80px;
  padding-bottom: 30px;
}
/* This is the styling for the right nav container */

.right-nav {
  flex: 70%;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  padding-bottom: 15px;
}

a {
  color: #ffffff;
  font-size: large;
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
  margin: 15px;
}
