.featured-container {
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: rgb(14, 14, 14);
  height: 500px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  height: max-content;
  flex-flow: row wrap;
}
.featured-container .featured-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 350px;
  align-items: left;
}
.flier {
  height: 250px;
  width: 250px;
}
.featured-content h1 {
  margin-top: 0px;
  color: white;
  text-align: left;
}
.featured-content p {
  color: white;
  text-align: left;
  margin-top: 15px;
  margin-bottom: 0px;
}
.featured-content button {
  width: 150px;
  height: 40px;
  margin-top: 15px;
  margin-left: -15px;
  border-radius: 5px;

  border: none;
  background-color: #ff0000;
  color: rgb(255, 255, 255);
  font-size: 16px;
}
.featured-content button:hover {
  cursor: pointer;
  background-color: rgb(255, 49, 49);
}
.featured-content button:active {
  cursor: pointer;
  transform: scale(1);
  box-shadow: 0px 1px 2.5px rgb(0, 0, 0);
}
