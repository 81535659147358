.header-container {
  height: 90vh;
  display: flex;
  padding: 15px;
  margin-bottom: -150px;
  background-image: url("../images/background.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  flex-flow: row wrap;
}
.header-container .header-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 400px;
}
.header-content h3 {
  margin-top: 100px;
  margin-bottom: 0px;
  text-align: left;
  font-size: 42px;
  color: white;
}
.header-content h2 {
  font-size: 16px;
  color: white;
  margin-bottom: 0px;
}
.header-content h4 {
  font-size: 24px;
  color: rgb(255, 208, 0);
  margin-top: 0px;
}
.header-content p {
  margin-top: 15px;
  font-size: 20px;
  color: white;
}
.header-content button {
  width: 150px;
  height: 40px;
  border: none;
  border-radius: 5px;

  margin-top: 0px;
  align-items: left;
  background-color: #ff0000;
  color: white;
  font-size: 16px;
}
.header-content a {
  color: white;
}

.header-content button:hover {
  cursor: pointer;
  background-color: rgb(255, 49, 49);
}
.header-content button:active {
  cursor: pointer;
  transform: scale(1);
  box-shadow: 0px 1px 2.5px rgb(180, 180, 180);
}
.header-content img {
  margin-top: 50px;
  height: 300px;
  width: 300px;
  padding: 50px;
}

.network-img img {
  margin-top: 35px;
  height: 350px;
  width: 350px;
}

@media only screen and (max-width: 900px) {
  .header-container {
    height: 100%;
    margin-bottom: -0px;
  }
  .header-content h1 {
    margin-top: 100px;
    font-size: 32px;
  }
  .header-content p {
    font-size: 18px;
  }
  .header-content img {
    margin-top: 20px;
    height: 300px;
    width: 300px;
  }
  .network-img img {
    margin-top: -150px;
    margin-bottom: -200px;
    height: 350px;
    width: 350px;
  }
}
