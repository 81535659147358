.nft-container {
    width: 100vw;
    margin: 0px;
    padding: 0px;
}


.summoner-images img {
    width: 20%;
    height: 100%;
    padding: 0px;
    margin-bottom: -10px;;
}