@import url('https://fonts.googleapis.com/css2?family=Bangers&display=swap');

.App {
  text-align: center;
}
body {
  padding: 0px;
  margin: 0px;
  background-color: whitesmoke;
}
h1,
h2, h4, 
p {
  font-family: Arial, Helvetica, sans-serif;
}
h3 {
font-family: 'Bangers', cursive;
}
.unity-canvas {
  width: 100%;
  height: 100vh;
}
